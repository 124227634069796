<template>
  <div>
    <div v-if="isloaded" class="dali-style">
      <div class="page-wrapper">
        <div class="section-box">
          <report-method
            api-prefix="daqc"
            @setName="changeName"
            @skip="handleSkip"
            class="report-method"
            v-if="!isTaxAutoProcessing"
            ref="showReport"
            :thirdDataSource="thirdDataSource"
            :zq="zq"
            :uuid="uuid"
            :company-info="companyInfo"
          ></report-method>
        </div>
      </div>
      <div class="footer2">
        <button @click="handleBtnNext" class="btn btn-primary btn-next">提交</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  breezeOuter,
  breezeOuterSDCheck,
  useAuthorize,
  zjBreezeLoginCreateTask,
  zjBreezeLoginCreateTaskBj,
  //
  newShanxiBreeze,
  zjBreezeLoginV2,
} from "@/api/api";

import reportMethod from "./report-method";

// Import component
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import { genTestReportOpen, getTaxAreaBureauServiceInfoOem } from "@/api/oem";
import appendQuery from "append-query";
import { Dialog } from "vant";

export default {
  data() {
    return {
      thirdDataSource: "", //山东取数的方式
      requiredTables: [],
      isAutoDataNum: 0,
      zjhm: "",
      czydm: "",
      zaNameValue: [],
      nameListArr: null,
      nameInfoList: "",
      identityValue: "",
      jsmobileCode: "",
      jscookies: "",
      jsinfo: "",
      dialogVisible: false,
      isloaded: false,
      processTexting: true,
      processTexted: false,
      progressUpload: false,
      progressUploadStatus: "0",
      tables: {},
      tipDialogVisible: false, //刚开始进入的提示框
      fullPage: true,
      isLoading: false,
      acknowledgeDialogVisible: false,
      uploadErrorDialogVisible: false, //控制无法识别的表的弹窗
      uploadErrors: [], //错误的表名及原因
      templateDownloadURL: "",
      hasItemsInList: false,
      actualZq: 0,
      zqs: [],
      showSDSDialog: false,
      uploadMethod: sessionStorage.getItem("method") || 1,
      code: 9996,
      index: -1,
      step2Msg: null,
      valueMsg: "",
      area: "",
      bizNo: "",
      radioIndex: "",
      passwordInput: "",
      clickQr: "",
      index1Data: "",
      indexJylsh: "",
      mobile: "",
      mobileCode: "",
      haveUploads: false,
      showTables: false,
      balanceName: "",
      tableData: [],
      taxSiteUsername: this.$store.state.companyInfo.nsrsbh || "",
      batchNo: "",
      isEnableAutoGetData: true, // 汇算清缴是否已经结束
      isTaxAutoProcessing: false, //自动取数进行中
      isVersionList: [9, 11, 15],
      baseVersion: 12, //房地产tag 需要基础信息表
      checkBaseInfoData: "",
      deleteDataType: this.$route.query.deleteDataType,

      // 内蒙
      nmCookies: "",
      nmPhone: "",
      nmMobileCode: "",
      nmIdentityValue: "",
      nminfoData: "",
      nmotherData: "",
      // 新疆
      xjmobileCode: "",
      xjbdgxId: "",
      xjbsrType: "",
      xjcookies: "",
      xjtaxSiteUsername: "",
      xjvalue: "",
      // 重庆
      cqcookies: "",
      cqmobileCode: "",
      cqmobile: "",
      cqloginStatusOne: "",
      // 北京
      bjadditionalPassword: "",
      bjcookies: "",
      bjnameInfoList: "",
      bjvalue: "",
      // 20220810新增
      bjNewPassword: "",
      bjnewIdentCode: "",
      bjNewIndenty: "",
      //
      bjNewName: "",
      newBjToken: "",
      bjNewValue: "",
      newZjBsry: "",
      // 辽宁
      lnmobile: "",
      lnVerifiTypeValue: "",
      lnmobileCode: "",
      lndtlSessionId: "",
      lndzswjTgc: "",
      // 广西
      gcmobileCode: "",
      gcVerifiTypeValue: "",
      gccookies: "",
      gcdjxh: "",
      gcczydm: "",
      gcsfmc: "",
      gcsfzjhm: "",
      // 河南
      hndqmobileCode: "",
      henanInfoListSelectValue: "",
      hndqCookies: "",
      hndqmobileCodeData: "",
      //
      henanInfoListSelectNext: false,
      loginStatusHN: "",
      nsrmcHN: "",
      useridHN: "",
      //重庆
      cqVerifiTypeNext: "",
      cqLoginType: "",
      cqmobileOne: "",
      cqVerifiTypeNextCode: false,
      // 湖南
      hunanCookies: "",
      hunanLoginStatus: "",
      hunanmobileCode: "",
      // 浙江新参数
      zjNewValue: "",
      newZjToken: "",
      newZjBsry: "",
      //
      addreportAndCheck: false,
      // 上海参数
      shpasswordInput: "",
      shBsrs: "",
      shRadio: "",
      shCookies: "",
      shmobileCode: "",
      // 陕西参数
      shanxiCookies: "",
      shanxiLoginData: "",
      shanxidqmobileCode: "",
      shanxiInfoListSelectValue: "",
      // 河北参数
      hebeiNameValue: "",
      hebeidqmobileCode: "",
      hebeiCookies: "",
      hebeiGetInfoBoolean: false,
      // 江西参数
      jiangxicookies: "",
      jiangxiData: "",
      jiangxinsrbh: "",
      jiangxinsrmc: "",
      jiangxidqmobileCode: "",
      jiangxiInfoListSelectValue: "",
      // 山西参数
      showShanXiPhoneData: "",
      showShanXiPhoneCode: "",
      sShanxiCookies: "",
      sShanxiloginStatus: "",
      // 福建
      fujianCookies: "",
      fujianPhoneCode: "",
      // 四川
      sichuanInfoData: "",
      sichuanOtherData: "",
      sichuanCookies: "",
      sichuanPhoneCode: "",
      sichuanMobile: "",
      // 天津
      tianJinMobile: "",
      // 黑龙江
      heiLongJOtherData: "",
      heiLongJinfo_data: "",
      heiLongJCookies: "",
      heiLongJPwd: "",
      heiLongJidentityValue: "",
      // 云南
      yunNanCookie: "",
      yunNanJPwd: "",
      yunNanUUID: "",
      yunnanRadio: "",
      yunNanJPhoneCode: "",
      // 吉林
      jiLinJMobile: "",
      jiLinJPhoneCode: "",
      jiLininfo_data: "",
      jiLincookies: "",
      // 青海
      qingHaiJPhoneCode: "",
      qinghaiInfo_data: "",
      qinghaiCookies: "",
      qinghaiOther_data: "",
      qinghaiLogin_status: "",
      qinghaiValObject: "",
      // 浙江新参数
      zjBsryxm: "",
      // 广东参数
      guangDongMobile: "",
      // 浙江新参数20230508
      zJSelectRoleValue: "",
      // 安徽参数
      anHuiJidentityValue: "",
      anhuiInfoData: "",
      anHuiJCookies: "",
      anHuiJOtherData: "",
      anHuisms_data: "",
      anHuiJPhoneCode: "",
      // 河南
      hnMobile: "",
      // 上海
      shanghaiother_data: "",
      shanghaiinfo_data: "",
      shanghaicookies: "",
      shNameListValue: ""
    };
  },
  components: {
    reportMethod,
  },
  props: {
    zq: [Number, String],
    productName: String,
    productID: [Number, String],
    zqLocation: [Number, String],
    bankId: String,
    dataPeriod: [Number, String],
    version: [Number, String],
    isShanDong: [Number, String],
  },
  computed: {
    ...mapState(["authorization"]),
    ...mapState("UploadTable", ["permissionID", "SDSSelection"]),
    ...mapState("oem", ["auth"]),
    isMobileDevice() {
      return window.innerWidth < 768;
    },
    token() {
      return "";
    },
    companyId() {
      return "";
    },
    uuid() {
      return this.auth.preInspectId;
    },
    nsrsbm() {
      return this.auth.companyNo;
    },
    nsrsbh() {
      return this.auth.companyTaxNo;
    },
    companyInfo() {
      return {
        nsrsbh: this.nsrsbh,
        companyName: this.auth.companyName,
        nsrsbm: this.auth.companyNo,
      };
    },
  },

  async mounted() {
    this.isloaded = false;
    if (!this.zq) {
      this.deviceAdaptiveAlert("征期不能为空");
      return;
    }
    // 是否自动取数中
    // await findTaxAutoStatusByUuid({
    //   uuid: this.auth.preInspectId,
    //   nsrsbm: this.auth.companyNo
    // }).then((res) => {
    //   if (res.msgCode == 1) {
    //     this.isTaxAutoProcessing = res.data;
    //   }
    // });
    // this.isTaxAutoProcessing = false;

    //如果税局未对接则手动填写报表
    const res = await getTaxAreaBureauServiceInfoOem({
      taxNo: this.nsrsbh,
    });

    if (!res.data.available) {
      this.deviceAdaptiveAlert("该地区暂不支持", {
        showClose: false,
        showConfirmButton: false,
      });
      return;
    } else {
      this.uploadMethod = 1;
    }

    //宽谷还是微风
    this.thirdDataSource = res.data.thirdDataSource;

    this.isloaded = true;
  },
  methods: {
    deviceAdaptiveAlert(message, title, options = {}) {
      if (!this.isMobileDevice) {
        this.$alert(message, title, options);
      } else {
        Dialog.alert({
          title: title,
          message: message,
          confirmButtonText: options.confirmButtonText,
        }).then(() => {
          if (options.callback) {
            options.callback();
          }
        });
      }
    },
    handleSkip() {
      // 报税版体验版会有的按钮，可以直接跳手动
      window.parent && window.parent.postMessage("upload-submit-success", "*");
    },
    handleBtnNext() {
      const callbackUrl = this.$route.query.cburl;
      let redirectedURL = callbackUrl
        ? appendQuery(callbackUrl, `programId=${this.uuid}`)
        : "";
      let params = {
        nsrsbm: this.nsrsbm,
      };

      this.useauthorize(() => {
        localStorage.setItem("taxSiteUsername", this.taxSiteUsername);
        // V2版本 用于事务所以及税务师
        if (this.$route.query.version == 2) {
          window.parent && window.parent.postMessage("v2-submit-success", "*");
          return;
        }
        // V2版本

        // V3版本 用于小程序
        if (this.$route.query.version == 3) {
          wx.miniProgram.postMessage({ data: "获取成功" });
          wx.miniProgram.navigateBack({ delta: 1, data: 2 });
          return;
        }
        // V3版本

        // 新的check接口
        let params = {
          //getDataWay: sessionStorage.getItem("method") == 2 ? 1 : 2,
          zq: this.zq,
          preInspectId: this.uuid,
        };
        const submitFn =
          this.$route.query.from == "lvat" ? breezeOuterSDCheck : genTestReportOpen;

        submitFn(params, "daqc").then((res) => {
          if (res.code == 200) {
            window.parent && window.parent.postMessage("thirdParty-submit-success", "*");

            if (callbackUrl) {
              redirectedURL = appendQuery(redirectedURL, "code=0");
              location.href = redirectedURL;
            } else {
              this.$router.push({
                name: "test-InvoiceUpload-oem",
              });
            }
          } else {
            if (callbackUrl) {
              redirectedURL = appendQuery(redirectedURL, "code=-1");
              location.href = redirectedURL;
            } else {
              //如果遇到"该服务暂不可用"就弹出错误信息并返回首页
              this.deviceAdaptiveAlert(res.msgInfo, "错误", {
                confirmButtonText: "确定",
                type: "error",
                callback: (action) => {
                  this.$router.push({ name: "homePage" });
                },
              });
            }
          }
        });
      });
    },
    // 子组件值变化传递到父组件
    changeName(val) {
      // console.log(val);
      this.taxSiteUsername = val.name;
      this.taxSitePwd = val.password;
      this.activeRead = val.active;
      this.index = val.index;
      this.step2Msg = val.step2Msg;
      this.valueMsg = val.value;
      this.area = val.province;
      this.bizNo = val.bizNo;
      this.radioIndex = val.radio;
      this.passwordInput = val.passwordInput;
      this.clickQr = val.clickQr;
      this.indexJylsh = val.indexJylsh;
      this.index1Data = val.index1Data;
      this.mobile = val.mobile;
      this.mobileCode = val.mobileCode;
      this.batchNo = val.batchNo;
      this.jsmobileCode = val.jsmobileCode;
      this.jscookies = val.jscookies;
      this.jsinfo = val.jsinfo;
      this.identityValue = val.identityValue;
      this.nameListArr = val.nameListArr;
      this.nameInfoList = val.nameInfoList;
      this.czydm = val.czydm;
      // 内蒙参数
      this.nmCookies = val.nmCookies;
      this.nmPhone = val.nmPhone;
      this.nmMobileCode = val.nmMobileCode;
      this.nmIdentityValue = val.nmIdentityValue;
      this.nminfoData = val.nminfoData;
      this.nmotherData = val.nmotherData;
      // 新疆
      this.xjmobileCode = val.xjmobileCode;
      this.xjbdgxId = val.xjbdgxId;
      this.xjbsrType = val.xjbsrType;
      this.xjcookies = val.xjcookies;
      this.xjtaxSiteUsername = val.xjtaxSiteUsername;
      this.xjvalue = val.xjvalue;
      // 重庆
      this.cqcookies = val.cqcookies;
      this.cqmobileCode = val.cqmobileCode;
      this.cqmobile = val.cqmobile;
      this.cqloginStatusOne = val.cqloginStatusOne;
      // 北京
      this.bjcookies = val.bjcookies;
      this.bjadditionalPassword = val.bjadditionalPassword;
      this.bjnameInfoList = val.bjnameInfoList;
      this.bjvalue = val.bjvalue;
      // 20220810新增
      this.bjNewPassword = val.bjNewPassword;
      this.bjnewIdentCode = val.bjnewIdentCode;
      //
      this.bjNewName = val.bjNewName;
      this.newBjToken = val.newBjToken;
      this.bjNewValue = val.bjNewValue;
      this.bjNewIndenty = val.bjNewIndenty;
      // 辽宁
      this.lnmobile = val.lnmobile;
      this.lnVerifiTypeValue = val.lnVerifiTypeValue;
      this.lnmobileCode = val.lnmobileCode;
      this.lndtlSessionId = val.lndtlSessionId;
      this.lndzswjTgc = val.lndzswjTgc;
      // 广西
      this.gcmobileCode = val.gcmobileCode;
      this.gcVerifiTypeValue = val.gcVerifiTypeValue;
      this.gccookies = val.gccookies;
      this.gcdjxh = val.gcdjxh;
      this.gcczydm = val.gcczydm;
      this.gcsfmc = val.gcsfmc;
      this.gcsfzjhm = val.gcsfzjhm;
      // 河南
      this.hndqmobileCode = val.hndqmobileCode;
      this.henanInfoListSelectValue = val.henanInfoListSelectValue;
      this.hndqCookies = val.hndqCookies;
      this.hndqmobileCodeData = val.hndqmobileCodeData;
      //
      this.henanInfoListSelectNext = val.henanInfoListSelectNext;
      this.loginStatusHN = val.loginStatusHN;
      this.nsrmcHN = val.nsrmcHN;
      this.useridHN = val.useridHN;
      //重庆
      this.cqVerifiTypeNext = val.cqVerifiTypeNext;
      this.cqLoginType = val.cqLoginType;
      this.cqmobileOne = val.cqmobileOne;
      this.cqVerifiTypeNextCode = val.cqVerifiTypeNextCode;
      // 湖南
      this.hunanCookies = val.hunanCookies;
      this.hunanLoginStatus = val.hunanLoginStatus;
      this.hunanmobileCode = val.hunandqmobileCode;
      //
      this.zjNewValue = val.zjNewValue;
      this.newZjToken = val.newZjToken;
      this.newZjBsry = val.newZjBsry;
      // 上海
      this.shpasswordInput = val.shpasswordInput;
      this.shBsrs = val.shBsrs;
      this.shRadio = val.shRadio;
      this.shCookies = val.shCookies;
      this.shmobileCode = val.shmobileCode;
      // 陕西
      this.shanxiCookies = val.shanxiCookies;
      this.shanxiLoginData = val.shanxiLoginData;
      this.shanxidqmobileCode = val.shanxidqmobileCode;
      this.shanxiInfoListSelectValue = val.shanxiInfoListSelectValue;
      // 河北
      this.hebeiNameValue = val.hebeiNameValue;
      this.hebeidqmobileCode = val.hebeidqmobileCode;
      this.hebeiCookies = val.hebeiCookies;
      this.hebeiGetInfoBoolean = val.hebeiGetInfoBoolean;
      // 江西
      this.jiangxicookies = val.jiangxicookies;
      this.jiangxiData = val.jiangxiData;
      this.jiangxinsrbh = val.jiangxinsrbh;
      this.jiangxinsrmc = val.jiangxinsrmc;
      this.jiangxidqmobileCode = val.jiangxidqmobileCode;
      this.jiangxiInfoListSelectValue = val.jiangxiInfoListSelectValue;
      // 山西
      this.showShanXiPhoneData = val.showShanXiPhoneData;
      this.showShanXiPhoneCode = val.showShanXiPhoneCode;
      this.sShanxiCookies = val.sShanxiCookies;
      this.sShanxiloginStatus = val.sShanxiloginStatus;
      // 福建
      this.fujianCookies = val.fujianCookies;
      this.fujianPhoneCode = val.fujianPhoneCode;
      // 四川
      this.sichuanInfoData = val.sichuanInfoData;
      this.sichuanOtherData = val.sichuanOtherData;
      this.sichuanCookies = val.sichuanCookies;
      this.sichuanPhoneCode = val.sichuanPhoneCode;
      this.sichuanMobile = val.sichuanMobile;
      // 天津
      this.tianJinMobile = val.tianJinMobile;
      // 黑龙江
      this.heiLongJOtherData = val.heiLongJOtherData;
      this.heiLongJinfo_data = val.heiLongJinfo_data;
      this.heiLongJCookies = val.heiLongJCookies;
      this.heiLongJPwd = val.heiLongJPwd;
      this.heiLongJidentityValue = val.heiLongJidentityValue;
      // 云南
      this.yunNanCookie = val.yunNanCookie;
      this.yunNanJPwd = val.yunNanJPwd;
      this.yunNanUUID = val.yunNanUUID;
      this.yunnanRadio = val.yunnanRadio;
      this.yunNanJPhoneCode = val.yunNanJPhoneCode;
      // 吉林
      this.jiLinJMobile = val.jiLinJMobile;
      this.jiLinJPhoneCode = val.jiLinJPhoneCode;
      this.jiLininfo_data = val.jiLininfo_data;
      this.jiLincookies = val.jiLincookies;
      // 青海
      this.qingHaiJPhoneCode = val.qingHaiJPhoneCode;
      this.qinghaiInfo_data = val.qinghaiInfo_data;
      this.qinghaiCookies = val.qinghaiCookies;
      this.qinghaiOther_data = val.qinghaiOther_data;
      this.qinghaiLogin_status = val.qinghaiLogin_status;
      this.qinghaiValObject = val.qinghaiValObject;
      // 浙江新参数
      this.zjBsryxm = val.zjBsryxm;
      // 广东参数
      this.guangDongMobile = val.guangDongMobile;
      // 浙江
      this.zJSelectRoleValue = val.zJSelectRoleValue;
      // 安徽
      this.anHuiJidentityValue = val.anHuiJidentityValue;
      this.anhuiInfoData = val.anhuiInfoData;
      this.anHuiJCookies = val.anHuiJCookies;
      this.anHuiJOtherData = val.anHuiJOtherData;
      this.anHuisms_data = val.anHuisms_data;
      this.anHuiJPhoneCode = val.anHuiJPhoneCode;
      // 河南
      this.hnMobile = val.hnMobile;
      // 上海概半
      this.shanghaiother_data = val.shanghaiother_data;
      this.shanghaiinfo_data = val.shanghaiinfo_data;
      this.shanghaicookies = val.shanghaicookies;
      this.shNameListValue = val.shNameListValue;
    },
    // 授权接口，授权之前的校验
    useauthorize(callback) {
      var uPattern = /^[a-zA-Z0-9]{15,20}$/;
      if (!uPattern.test(this.taxSiteUsername)) {
        this.$message.warning("请输入账号密码");
        return;
      }
      // if (!this.taxSitePwd) {
      //   this.$message.warning("密码不能为空！");
      //   return;
      // }
      if (this.clickQr) {
        this.deviceAdaptiveAlert("请点击验证按钮！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      // // 浙江地区校验
      // if (!this.valueMsg && this.index == 0) {
      //   this.deviceAdaptiveAlert("请选择办税人员！", "温馨提示", {
      //     confirmButtonText: "确定",
      //   });
      //   return;
      // }

      // // 宁波地区校验
      // if (!this.mobile && this.index == 1) {
      //   this.deviceAdaptiveAlert("手机号不能为空！", "温馨提示", {
      //     confirmButtonText: "确定",
      //   });
      //   return;
      // }
      if (!this.mobileCode && this.index == 1) {
        this.deviceAdaptiveAlert("验证码不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      //
      if (!this.identityValue && this.index == 2) {
        this.deviceAdaptiveAlert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (!this.jsmobileCode && this.index == 2) {
        this.deviceAdaptiveAlert("请输入验证码！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      // if(this.index == 3 && !this.zjhm) {
      //   this.deviceAdaptiveAlert("请输入操作人员身份证件号！", "温馨提示", {
      //     confirmButtonText: "确定",
      //   });
      //   return;
      // }

      if (this.index == 3 && !this.czydm) {
        this.deviceAdaptiveAlert(
          "请输入委托人手机号/身份证号码/操作员代码！",
          "温馨提示",
          {
            confirmButtonText: "确定",
          }
        );
        return;
      }

      // if (this.index == 4 && !this.nmIdentityValue) {
      //   this.deviceAdaptiveAlert("请选择身份！", "温馨提示", {
      //     confirmButtonText: "确定",
      //   });
      //   return;
      // }

      if (this.index == 4 && !this.nmPhone) {
        this.deviceAdaptiveAlert("手机号不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 4 && !this.nmMobileCode) {
        this.deviceAdaptiveAlert("验证码不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 5 && !this.xjvalue) {
        this.deviceAdaptiveAlert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 5 && !this.xjmobileCode) {
        this.deviceAdaptiveAlert("验证码不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 7 && !this.cqmobile) {
        this.deviceAdaptiveAlert("用户名不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 7 && this.cqVerifiTypeNext) {
        this.deviceAdaptiveAlert("请输入用户名之后，点击下一步", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (
        this.index == 7 &&
        this.cqLoginType == "smsAuthentication" &&
        !this.cqmobileOne
      ) {
        this.deviceAdaptiveAlert("手机号不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (
        this.index == 7 &&
        this.cqLoginType == "smsAuthentication" &&
        !this.cqmobileCode
      ) {
        this.deviceAdaptiveAlert("验证码不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      // cqVerifiTypeNextCode
      if (this.index == 7 && this.cqVerifiTypeNextCode) {
        this.deviceAdaptiveAlert("请输入手机号和验证码之后，点击下一步", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 7 && this.cqloginStatusOne == "sfChoose" && !this.cqmobileCode) {
        this.deviceAdaptiveAlert("验证码不能为空", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 8 && !this.bjvalue) {
        this.deviceAdaptiveAlert("请选择办税人员！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 8 && !this.bjNewPassword) {
        this.deviceAdaptiveAlert("手机号不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 8 && !this.bjnewIdentCode) {
        this.deviceAdaptiveAlert("身份证号不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 8 && !this.bjadditionalPassword) {
        this.deviceAdaptiveAlert("密码不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 9 && !this.lnVerifiTypeValue) {
        this.deviceAdaptiveAlert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 9 && !this.lnmobile) {
        this.deviceAdaptiveAlert("请输入手机号！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 9 && !this.lnmobileCode) {
        this.deviceAdaptiveAlert("请输入验证码！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 10 && !this.gcVerifiTypeValue) {
        this.deviceAdaptiveAlert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 10 && !this.gcmobileCode) {
        this.deviceAdaptiveAlert("请输入验证码！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      // if (this.index == 11 && !this.henanInfoListSelectValue) {
      //   this.deviceAdaptiveAlert("请选择身份！", "温馨提示", {
      //     confirmButtonText: "确定",
      //   });
      //   return;
      // }

      if (this.index == 11 && !this.hndqmobileCode) {
        this.deviceAdaptiveAlert("请输入验证码！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      // if (this.index == 11 && this.henanInfoListSelectNext) {
      //   this.deviceAdaptiveAlert("先点击下一步！", "温馨提示", {
      //     confirmButtonText: "确定",
      //   });
      //   return;
      // }

      if (
        this.index == 12 &&
        this.hunanLoginStatus == "sfChoose" &&
        !this.hunanmobileCode
      ) {
        this.deviceAdaptiveAlert("验证码不能为空", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      // if (this.index == 13 && !this.shBsrs) {
      //   this.deviceAdaptiveAlert("请选择办税人员！", "温馨提示", {
      //     confirmButtonText: "确定",
      //   });
      //   return;
      // }

      // if (this.index == 13 && this.shRadio == 1 && !this.shpasswordInput) {
      //   this.deviceAdaptiveAlert("请输入密码！", "温馨提示", {
      //     confirmButtonText: "确定",
      //   });
      //   return;
      // }

      // if (this.index == 13 && this.shRadio == 2 && !this.shmobileCode) {
      //   this.deviceAdaptiveAlert("请输入验证码！", "温馨提示", {
      //     confirmButtonText: "确定",
      //   });
      //   return;
      // }

      // shanxiInfoListSelectValue
      if (
        this.index == 14 &&
        !this.shanxiInfoListSelectValue &&
        this.thirdDataSource == "DB_WF"
      ) {
        this.deviceAdaptiveAlert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      // 河北
      if (this.index == 15 && !this.hebeiGetInfoBoolean) {
        this.deviceAdaptiveAlert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 15 && !this.hebeidqmobileCode) {
        this.deviceAdaptiveAlert("验证码不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 16 && !this.jiangxiInfoListSelectValue) {
        this.deviceAdaptiveAlert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 16 && !this.jiangxidqmobileCode) {
        this.deviceAdaptiveAlert("请输入验证码！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (!this.activeRead) {
        this.deviceAdaptiveAlert("请阅读并勾选授权协议", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      // // 如果是选择密码方式，密码不能为空
      // if (!this.passwordInput && this.index == 0 && this.radioIndex == 2) {
      //   this.deviceAdaptiveAlert("密码不能为空！", "温馨提示", {
      //     confirmButtonText: "确定",
      //   });
      //   return;
      // }

      if (this.clickQr) {
        this.deviceAdaptiveAlert("请先点击验证按钮", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      const loading = this.$loading({
        lock: true,
        text: "正在提交数据,请耐心等待！",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      // 通用授权
      if (this.index == -1) {
        let params = {
          id: this.companyId,
          authorizeResult: true,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          taxpayerId: this.taxSiteUsername,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq,
          batchNo: this.batchNo,
        };
        useAuthorize(params, "daqc").then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 浙江地区授权
      if (this.index == 0) {
        if (this.thirdDataSource == "DB_WF") {
          let params = {
            id: this.companyId,
            nsrsbm: this.nsrsbm,
            uuid: this.uuid,
            tag: this.version,
            token: this.token,
            zq: this.zq,
            taxpayerId: this.taxSiteUsername,
            taxSiteUsername: this.taxSiteUsername,
            taxSitePwd: this.taxSitePwd,
            acwTc: this.step2Msg.acw_tc,
            aliyungfTc: this.step2Msg.aliyungf_tc,
            dtlSessionId: this.step2Msg.dtl_SESSION_ID,
            loginType: this.radioIndex == 1 ? "SMRZ" : "PWD",
            additionalUsername: this.valueMsg,
            additionalPassword: this.radioIndex == 1 ? this.bizNo : this.passwordInput,
            area: this.area,
            batchNo: this.batchNo,
            cookies: this.step2Msg,
            djxh: this.nameInfoList.djxh,
            nsrmc: this.nameInfoList.nsrmc,
            sfzjhm: this.nameInfoList.sfzjhm,
            sjhm: this.nameInfoList.sjhm,
            swjgDm: this.nameInfoList.swjgDm,
            type: this.nameInfoList.type,
            zjUuid: this.nameInfoList.uuid,
            xm: this.nameInfoList.xm,
          };
          breezeOuter(params, "daqc").then((res) => {
            loading.close();
            if (res.msgCode == 1) {
              callback();
            } else {
              this.$refs.showReport.getprovinceBath();
              this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
                confirmButtonText: "确定",
              });
              return false;
            }
          });
        } else {
          let params = {
            // czydm: this.zjNewValue,
            bsryxm: this.zjBsryxm, //手机号或者身份证号
            companyNo: this.nsrsbm,
            password: this.taxSitePwd.trim(),
            programId: this.uuid,
            userId: this.taxSiteUsername.trim(),
            zq: this.zq,
            dlsf: this.zJSelectRoleValue,
            // bsry: this.newZjBsry,
          };
          zjBreezeLoginV2(params, "daqc").then((res) => {
            loading.close();
            if (res.msgCode == 1) {
              callback();
            } else {
              this.$refs.showReport.getprovinceBath();
              this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
                confirmButtonText: "确定",
              });
              return false;
            }
          });
        }
      }

      // 宁波授权
      if (this.index == 1) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq,
          //
          taxpayerId: this.taxSiteUsername.trim(),
          taxSiteUsername: this.mobile.trim(),
          taxSitePwd: this.taxSitePwd.trim(),
          batchNo: this.batchNo,
          area: this.area,
          otherData: this.indexJylsh,
          cookies: this.index1Data,
          smsCode: this.mobileCode.trim(),
        };
        breezeOuter(params, "daqc").then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 江苏授权
      if (this.index == 2) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq,
          taxpayerId: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          smsCode: this.jsmobileCode,
          batchNo: this.batchNo,
          area: this.area,
          jsLoginType: "1",
          rybz: this.jsinfo.rybz,
          yxbz: this.jsinfo.yxbz,
          bz: this.jsinfo.bz,
          xm: this.jsinfo.xm,
          tel: this.jsinfo.tel,
          sjhm: this.jsinfo.sjhm,
          sfzh: this.jsinfo.sfzh,
          zjlx: this.jsinfo.zjlx,
          type: this.jsinfo.type,
          cookies: this.jscookies,
        };
        breezeOuter(params, "daqc").then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 山东授权
      if (this.index == 3) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq,

          taxpayerId: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          batchNo: this.batchNo,
          area: this.area,
          czydm: this.czydm,
          taxSiteUsername: this.czydm,
          thirdDataSource: this.thirdDataSource,
        };
        breezeOuter(params, "daqc").then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 内蒙授权
      if (this.index == 4) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq,
          taxpayerId: this.taxSiteUsername.trim(),
          taxSitePwd: this.taxSitePwd.trim(),
          area: this.area,
          batchNo: this.batchNo,
          cookies: this.nmCookies,
          // legalMobile: this.nmPhone,
          smsCode: this.nmMobileCode,
          taxSiteUsername: this.nmPhone,
          infoData: this.nminfoData,
          otherData: this.nmotherData,
        };
        breezeOuter(params, "daqc").then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 新疆授权
      if (this.index == 5) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq,

          taxpayerId: this.taxSiteUsername.trim(),
          taxSitePwd: this.taxSitePwd.trim(),
          area: this.area,
          batchNo: this.batchNo,
          smsCode: this.xjmobileCode,
          bdgxId: this.xjbdgxId,
          bsrType: this.xjbsrType,
          cookies: this.xjcookies,
          taxSiteUsername: this.xjtaxSiteUsername,
        };
        breezeOuter(params, "daqc").then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 重庆授权
      if (this.index == 7) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq,

          taxpayerId: this.taxSiteUsername.trim(),
          taxSiteUsername: this.cqmobile.trim(),
          taxSitePwd: this.taxSitePwd.trim(),
          area: this.area,
          batchNo: this.batchNo,
          smsCode: this.cqmobileCode,
          cookies: this.cqcookies,
          loginStatus: this.cqloginStatusOne,
        };
        breezeOuter(params, "daqc").then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 北京授权
      if (this.index == 8) {
        if (this.thirdDataSource == "DB_WF") {
          let params = {
            id: this.companyId,
            nsrsbm: this.nsrsbm,
            uuid: this.uuid,
            tag: this.version,
            token: this.token,
            zq: this.zq,
            //
            taxpayerId: this.taxSiteUsername,
            taxSiteUsername: this.taxSiteUsername,
            taxSitePwd: this.taxSitePwd,
            loginType: 1,
            additionalUsername: this.taxSiteUsername,
            additionalPassword: this.bjadditionalPassword,
            area: this.area,
            batchNo: this.batchNo,
            cookies: this.bjcookies,
            //
            xm: this.bjnameInfoList.xm,
            sjhm: this.bjnameInfoList.sjhm,
            sfzjhm: this.bjnameInfoList.zjhm,
            sfmc: this.bjnameInfoList.sfmc,
            key: this.bjnameInfoList.key,
            // 20220810新增
            confirmCellphone: this.bjNewPassword,
            confirmIdNumber: this.bjnewIdentCode,
          };
          breezeOuter(params, "daqc").then((res) => {
            loading.close();
            if (res.msgCode == 1) {
              callback();
            } else {
              this.$refs.showReport.getprovinceBath();
              this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
                confirmButtonText: "确定",
              });
              return false;
            }
          });
        } else {
          let params = {
            bsryxm: this.bjNewName,
            czydm: this.bjNewValue,
            dlsf: this.bjNewIndenty,
            sfzjhm: this.bjnewIdentCode, //
            sjhm: this.bjNewPassword, //
            password: this.bjadditionalPassword, //
            programId: this.uuid, //
            token: this.newBjToken,
            zq: this.zq, //
          };
          zjBreezeLoginCreateTaskBj(params, "daqc").then((res) => {
            loading.close();
            if (res.msgCode == 1) {
              callback();
            } else {
              this.$refs.showReport.getprovinceBath();
              this.$alert(res.msgInfo, "温馨提示", {
                confirmButtonText: "确定",
              });
              return false;
            }
          });
        }
      }

      // 辽宁授权
      if (this.index == 9) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq,
          //
          batchNo: this.batchNo,
          area: this.area,
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          loginType: this.lnVerifiTypeValue,
          phone: this.lnmobile,
          smsCode: this.lnmobileCode,
          dtlSessionId: this.lndtlSessionId,
          dzswjTgc: this.lndzswjTgc,
        };
        breezeOuter(params, "daqc").then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 广西授权
      if (this.index == 10) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq,
          //
          batchNo: this.batchNo,
          area: this.area,
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          smsCode: this.gcmobileCode,
          cookies: this.gccookies,
          djxh: this.gcdjxh,
          czydm: this.gcczydm,
          sfmc: this.gcsfmc,
          sfzjhm: this.gcsfzjhm,
        };
        breezeOuter(params, "daqc").then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 河南授权
      if (this.index == 11) {
        this.hndqmobileCodeData.sms_code = this.hndqmobileCode;
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq,
          //
          batchNo: this.batchNo,
          area: this.area,
          taxpayerId: this.taxSiteUsername,
          additionalUsername: this.taxSiteUsername,
          additionalPassword: this.taxSitePwd,
          // 
          // smsCode: this.hndqmobileCode,
          // djxh: this.henanInfoListSelectValue,
          cookies: this.hndqCookies,
          otherData: this.hndqmobileCodeData,
          // wsbsfwtsessionId: this.hndqCookies,
          // data: this.hndqmobileCodeData,
          // // 20221025新增
          // loginStatus: this.loginStatusHN,
          // nsrmc: this.nsrmcHN,
          // bdgxId: this.useridHN,
        };
        breezeOuter(params, "daqc").then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 湖南授权
      if (this.index == 12) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq,
          //
          batchNo: this.batchNo,
          area: this.area,
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          smsCode: this.hunanmobileCode,
          cookies: this.hunanCookies,
          loginStatus: this.hunanLoginStatus,
        };
        breezeOuter(params, "daqc").then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 上海授权
      if (this.index == 13) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq,
          //
          // 验证码
          smsCode: this.shmobileCode,
          //
          batchNo: this.batchNo,
          area: this.area,
          taxpayerId: this.taxSiteUsername,
          additionalUsername: this.shNameListValue,
          additionalPassword: this.taxSitePwd, // 二级密码
          otherData: this.shanghaiother_data,
          cookies: this.shanghaicookies,
        };
        breezeOuter(params, "daqc").then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 陕西授权
      if (this.index == 14) {
        if (this.thirdDataSource == "DB_WF") {
          let params = {
            id: this.companyId,
            nsrsbm: this.nsrsbm,
            uuid: this.uuid,
            tag: this.version,
            token: this.token,
            zq: this.zq,
            //
            taxpayerId: this.taxSiteUsername,
            taxSiteUsername: this.taxSiteUsername,
            taxSitePwd: this.taxSitePwd,
            area: this.area,
            cookies: this.shanxiCookies,
            data: this.shanxiLoginData ? JSON.stringify(this.shanxiLoginData) : "",
            smsCode: this.shanxidqmobileCode,
            batchNo: this.batchNo,
          };
          breezeOuter(params, "daqc").then((res) => {
            loading.close();
            if (res.msgCode == 1) {
              callback();
            } else {
              this.$refs.showReport.getprovinceBath();
              this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
                confirmButtonText: "确定",
              });
              return false;
            }
          });
        } else {
          let params = {
            yzm: this.shanxidqmobileCode,
            programId: this.uuid,
            zq: this.zq,
          };
          newShanxiBreeze(params, "daqc").then((res) => {
            loading.close();
            if (res.msgCode == 1) {
              callback();
            } else {
              this.$refs.showReport.getprovinceBath();
              this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
                confirmButtonText: "确定",
              });
              return false;
            }
          });
        }
      }

      // 河北授权
      if (this.index == 15) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq,
          //
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.hebeiNameValue,
          taxSitePwd: this.taxSitePwd,
          area: this.area,
          batchNo: this.batchNo,
          smsCode: this.hebeidqmobileCode,
          cookies: this.hebeiCookies,
        };
        breezeOuter(params, "daqc").then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 江西授权
      if (this.index == 16) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq,
          //
          taxpayerId: this.taxSiteUsername,
          area: this.area,
          batchNo: this.batchNo,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          //
          cookies: this.jiangxicookies,
          data: JSON.stringify(this.jiangxiData),
          swjgDm: this.jiangxinsrbh,
          nsrmc: this.jiangxinsrmc,
          smsCode: this.jiangxidqmobileCode,
        };
        breezeOuter(params, "daqc").then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }
      // 山西授权
      if (this.index == 17) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq,
          //
          taxpayerId: this.taxSiteUsername,
          area: this.area,
          batchNo: this.batchNo,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          //
          cookies: this.sShanxiCookies,
          phone: this.showShanXiPhoneData,
          smsCode: this.showShanXiPhoneCode,
          loginStatus: this.sShanxiloginStatus,
        };
        breezeOuter(params, "daqc").then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 福建授权
      if (this.index == 18) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq,
          //
          taxpayerId: this.taxSiteUsername,
          area: this.area,
          batchNo: this.batchNo,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          //
          legalMobile: "", //手机号
          smsCode: this.fujianPhoneCode,
          dzswjTgc: this.fujianCookies.dzswj_TGC,
          tgc: this.fujianCookies.tgc,
          jsessionid: this.fujianCookies.jsessionid,
          route: this.fujianCookies.route,
        };
        breezeOuter(params, "daqc").then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 四川授权
      if (this.index == 19) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq,
          //
          taxpayerId: this.taxSiteUsername,
          area: this.area,
          batchNo: this.batchNo,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          //
          smsCode: this.sichuanPhoneCode,
          infoData: this.sichuanInfoData,
          otherData: this.sichuanOtherData,
          cookies: this.sichuanCookies,
        };
        breezeOuter(params, "daqc").then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 天津授权
      if (this.index == 20) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq,
          //
          taxpayerId: this.taxSiteUsername,
          area: this.area,
          batchNo: this.batchNo,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          //
          legalMobile: this.tianJinMobile,
        };
        breezeOuter(params, "daqc").then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      //
      // 黑龙江
      if (this.index == 21) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq,
          //
          taxpayerId: this.taxSiteUsername,
          area: this.area,
          batchNo: this.batchNo,
          taxSitePwd: this.heiLongJPwd,
          //
          otherData: this.heiLongJOtherData,
          infoData: this.heiLongJidentityValue,
          cookies: this.heiLongJCookies,
        };
        breezeOuter(params, "daqc").then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }
      // 云南
      if (this.index == 22) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq,
          //
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.taxSiteUsername,
          area: this.area,
          batchNo: this.batchNo,
          taxSitePwd: this.yunnanRadio == "PWD" ? this.yunNanJPwd : this.taxSitePwd,
          //
          dtlSessionId: this.yunNanCookie.dtl_SESSION_ID,
          acwTc: this.yunNanCookie.acw_tc,
          zjUuid: this.yunNanUUID,
          loginType: this.yunnanRadio,
          smsCode: this.yunNanJPhoneCode,
        };
        breezeOuter(params, "daqc").then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }
      // 吉林
      if (this.index == 23) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq,
          //
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.taxSiteUsername,
          area: this.area,
          batchNo: this.batchNo,
          taxSitePwd: this.taxSitePwd,
          //
          phone: this.jiLinJMobile,
          smsCode: this.jiLinJPhoneCode,
          infoData: this.jiLininfo_data,
          cookies: this.jiLincookies,
        };
        breezeOuter(params, "daqc").then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }
      // 青海
      if (this.index == 24) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq,
          //
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.taxSiteUsername,
          area: this.area,
          batchNo: this.batchNo,
          taxSitePwd: this.taxSitePwd,
          //
          otherData: this.qinghaiOther_data,
          smsCode: this.qingHaiJPhoneCode,
          infoData: this.qinghaiValObject,
          cookies: this.qinghaiCookies,
          loginStatus: this.qinghaiLogin_status,
        };
        breezeOuter(params, "daqc").then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }
      // 广东
      if (this.index == 25) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq,
          //
          taxpayerId: this.taxSiteUsername,
          area: this.area,
          batchNo: this.batchNo,
          taxSitePwd: this.taxSitePwd,
          //
          taxSiteUsername: this.guangDongMobile,
        };
        breezeOuter(params, "daqc").then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }
      // 安徽
      if (this.index == 26) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq,
          //
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.taxSiteUsername,
          area: this.area,
          batchNo: this.batchNo,
          taxSitePwd: this.taxSitePwd,
          //
          infoData: this.anhuiInfoData,
          cookies: this.anHuiJCookies,
          otherData: this.anHuiJOtherData,
          // data: JSON.stringify(this.anHuisms_data),
          tgc: this.anHuisms_data.qr_token,
          zjUuid: this.anHuisms_data.qr_uuid,
        };
        breezeOuter(params, "daqc").then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }
    },
  },
  watch: {},
};
</script>
<style src="@/styles/index.styl" lang="stylus" scoped></style>
<style lang="stylus" scoped>
.tip {
  margin 0 48px !important
  width auto !important
}

.data-loading-wrapper {
  height: 722px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .text {
    margin-top: 47px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    line-height: 25px;
  }
}

.modify-table-wrapper {
  margin-top: 30px;
}

.correctDialog {
  padding: 0;

  >>> .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }
}

.tipDialog {
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    box-sizing: border-box;

    .img {
      width: 174px;
      flex-shrink: 0;
    }

    .right-wrapper {
      margin-left: 50px;

      .title {
        font-weight: bold;
        font-size: 18px;
        color: #062340;
      }

      .desc {
        margin-top: 16px;
        color: #6b7897;
        font-size: 16px;
        line-height: 1.5;
      }

      .btn-i-get {
        height: 40px;
        line-height: 40px;
        margin-top: 17px;
      }
    }
  }
}

.the-title {
  margin: 30px 30px 30px 0;
}

.content-body {
  margin: 20px;
  background: #fff;
  border-radius: 8px;
  padding: 0;
}

.upload-footer {
  margin-top: 16px;

  .tip {
    float: left;
    color: #FAAD14;
    font-size: 14px;
  }

  .acknowlege, .btn-download {
    background: #fff;
    font-size: 14px;
    float: right;
    color: #2F54EB;
    vertical-align: middle;

    .icon {
      vertical-align: middle;
      font-size: 22px;
    }
  }

  .acknowlege {
    margin-right: 48px;
    cursor: pointer;
    float: right;
  }

  .acknowlege:hover, .btn-download:hover {
  }
}

.table-list {
  margin-top: 24px;
}

.footer {
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;

  .btn {
    margin: 0 10px;
  }

  .btn-purge-data {
    color: #ff6464;
  }
}

// >>> .el-dialog__header {
// padding: 0;
// }

// >>> .section-title {
// margin-top: 10px;
// }
.section-box {
  padding 0

  .title {
    color: rgba(0, 0, 0, 0.8);
    padding 16px 24px
    font-size: 18px;
    font-weight: 600;
    border-bottom: 1px solid #eeeeee;
    text-align left

    .tips {
      font-size: 14px;
      color: #f5222d;
      margin-left: 16px;
    }
  }
}

.page-wrapper {
  // padding-bottom: 84px;
}

.progressdis {
  width: 90%;
  margin: 2px auto 16px;
  margin-bottom: 0;
}

.btn-right-none {
  margin-right: 6px !important;
}

.report-method {
  margin-top: 16px;
}

.nomargin {
  margin: 0 30px;
  padding: 0;
}

.title-content {
  // width: 1260px;
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px 0px rgba(238, 238, 238, 1);
}

>>> .bread-crumb-wrapper {
  margin-top: 0;
}

.mainHeader {
  display: flex;
  justify-content: space-between;
}

.text-status {
  width: 10%;
}

.tab-wrapper {
  border-bottom 1px solid #d9d9d9;
}

.uploadMethodText {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 24px;
  align-items center
  justify-content center
}

.uploadMethodItem {
  padding 0px 50px
  height: 49px;
  line-height: 49px;
  text-align: center;
  background: #fff;
  border-radius: 18px 18px 0 0;
  display flex
  cursor: pointer;
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
}

.zqLocation {
  color: #2F54EB;
  font-weight 600
  border: 1px solid #D9D9D9;
  border-bottom 0 none
}

.fix-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  background: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  margin-bottom: 24px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.fix-flexs {
  display: flex;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(47, 84, 235, 1);
}

.fix-flexs1 {
  margin-left: 24px;
}

.byHand {
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(47, 84, 235, 1);
  line-height: 22px;
  margin-bottom: 20px;
  cursor: pointer;
  margin-top: 24px;
}

.drog_texts {
  padding-top: 10px;
  padding-bottom: 14px;
  width: 304px;
}

.upload-text {
  display: flex;
  width: 100%;
  overflow: hidden;
  box-sizing border-box
}

.table-lists {
  flex: 1;
  margin-top: 0;
  padding-top: 0px;
}


// 余额表
.tables {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-left: -550px;
  margin-top: -300px;
  width: 1158px;
  height: 700px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 2000;
  padding-bottom: 30px;
  overflow-y: auto;
}

.content {
  width: 1136px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 0 auto;
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  text-align: center;
  margin: 24px 0;
}

.closes {
  position: absolute;
  right: 25px;
  top: 17px;
  font-size: 32px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.fix-flexsL {
  width: 25%;
  padding-right: 32px;
}

.fix-flexsR {
  width: 75%;
}

.fix-flexsLr {
  width: 100%;
  // max-width: auto;
}

.flex-max-widh {
  max-width: 304px;
}

.totip {
  position: absolute;
  left: 390px;
  top: 8px;
}

.noPadding {
  padding-right: 0;
}

.progressUpload {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2022;
}

.progressBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 638px;
  height: 406px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.processText {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #2F54EB;
  line-height: 28px;
  margin-top: 25px;
}

.processTexted {
  color: #52C41A;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  line-height: 28px;
  margin-top: 25px;
}

>>> .el-progress--circle .el-progress__text, .el-progress--dashboard .el-progress__text {
  top: 80%;
}

.undraw_upload {
  position: absolute;
  width: 180px;
  height: 132px;
  top: 90px;
}

.processTextedNo {
  visibility: hidden;
}

.baseVersion {
  height: 36px;
  line-height: 36px;
  padding: 0 6px;
  background: #2F54EB;
  margin: 4px 6px 0 0;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}

>>> .footer-wrapper .inner {
  margin-left 0
}

.footer2 {
  margin-left: 496px;
  .btn {
    width: 100px;
  }
}

@media screen and (max-width: 768px) {
  .section-box {
    background red
    margin: 0 10px
    box-shadow none

    .container {

    }


  }

  .btn-next {
    margin 0 !important
  }

  .footer2 {
    margin-left: 10px;
    margin-bottom: 30px;
  }
}
</style>
