var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    { staticClass: "container dali-style", attrs: { autocomplete: "off" } },
    [
      _vm._m(0),
      _c("div"),
      _c(
        "div",
        { staticClass: "user field" },
        [
          _c("div", { staticClass: "label required" }, [
            _vm._v("纳税人识别号："),
          ]),
          _c("el-input", {
            attrs: { disabled: "", placeholder: "请输入" },
            on: { input: _vm.changeName },
            model: {
              value: _vm.companyInfo.nsrsbh,
              callback: function ($$v) {
                _vm.$set(
                  _vm.companyInfo,
                  "nsrsbh",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "companyInfo.nsrsbh",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "user field" },
        [
          _c("div", { staticClass: "label required" }, [_vm._v("企业名称：")]),
          _c("el-input", {
            attrs: {
              value: _vm.companyInfo.companyName,
              disabled: "",
              placeholder: "请输入",
            },
            on: { input: _vm.changeName },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "user field" },
        [
          _c(
            "div",
            { staticClass: "label required" },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item pc-device-only",
                  attrs: { effect: "dark", placement: "top-start" },
                },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _vm._v(
                      " 企业登录电子税务局网站的账号，一般为纳税人识别号、法人身份证、用户名等。 "
                    ),
                  ]),
                  _c("i", {
                    staticClass: "el-icon-warning",
                    staticStyle: {
                      position: "absolute",
                      left: "10px",
                      top: "-1px",
                      "font-size": "18px",
                      color: "#d9d9d9",
                    },
                  }),
                ]
              ),
              _vm._v(" 电子税务局账号： "),
              _c("div", { staticClass: "tool-tip-text mobile-device-only" }, [
                _vm._v(
                  " 企业登录电子税务局网站的账号，一般为纳税人识别号、法人身份证、用户名等。 "
                ),
              ]),
            ],
            1
          ),
          _c("el-input", {
            attrs: { placeholder: "请输入" },
            on: { input: _vm.changeName },
            model: {
              value: _vm.name,
              callback: function ($$v) {
                _vm.name = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "name",
            },
          }),
        ],
        1
      ),
      this.province != "黑龙江"
        ? _c(
            "div",
            { staticClass: "user field" },
            [
              _c(
                "div",
                { staticClass: "label required" },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item pc-device-only",
                      attrs: { effect: "dark", placement: "top-start" },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _vm._v(
                            " 企业登录电子税务局网站的密码或个人密码，如果忘记密码，请先在电子税务局页面找回密码后登录。 "
                          ),
                        ]
                      ),
                      _c("i", {
                        staticClass: "el-icon-warning",
                        staticStyle: {
                          position: "absolute",
                          left: "10px",
                          top: "-1px",
                          "font-size": "18px",
                          color: "#d9d9d9",
                        },
                      }),
                    ]
                  ),
                  _vm._v(
                    " " +
                      _vm._s(
                        this.areaIndex == 0 &&
                          this.thirdDataSource == "DB_KUANGU"
                          ? "个人用户密码："
                          : "电子税务局密码："
                      ) +
                      " "
                  ),
                  _c(
                    "div",
                    { staticClass: "tool-tip-text mobile-device-only" },
                    [
                      _vm._v(
                        " 企业登录电子税务局网站的密码，如果忘记密码，请先在电子税务局页面找回密码后登录。 "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("el-input", {
                attrs: {
                  type: "password",
                  "auto-complete": "new-password",
                  placeholder: "请输入",
                  "show-password": true,
                },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.password,
                  callback: function ($$v) {
                    _vm.password = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "password",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "btn-wrapper" }, [
        _vm.clickQr
          ? _c(
              "div",
              { staticClass: "clickQr ease", on: { click: _vm.clickQrs } },
              [_vm._v("点击验证")]
            )
          : _vm._e(),
      ]),
      _vm.anHuiJSelect
        ? _c(
            "div",
            { staticClass: "user field", attrs: { id: "selectInfo" } },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("身份选择："),
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.anHuiJgetCode },
                  model: {
                    value: _vm.anHuiJidentityValue,
                    callback: function ($$v) {
                      _vm.anHuiJidentityValue = $$v
                    },
                    expression: "anHuiJidentityValue",
                  },
                },
                _vm._l(_vm.anHuikeyArray, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.anHuiJSelect
        ? _c(
            "div",
            {
              staticClass: "user field selectInfoQr",
              attrs: { id: "selectInfo" },
            },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("选择身份后扫码："),
              ]),
              _c("div", { staticClass: "labelQcode" }, [
                _c("img", {
                  attrs: {
                    src: "data:image/Jpeg;base64," + _vm.anHuilabelQcode,
                    alt: "",
                  },
                }),
              ]),
            ]
          )
        : _vm._e(),
      _vm.guangDongJPhone
        ? _c(
            "div",
            { staticClass: "user field" },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("账号/身份证号/手机号码："),
              ]),
              _c("el-input", {
                staticClass: "input",
                attrs: { placeholder: "请输入" },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.guangDongMobile,
                  callback: function ($$v) {
                    _vm.guangDongMobile =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "guangDongMobile",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.qingHaiJPhone
        ? _c(
            "div",
            { staticClass: "user field" },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("手机号码："),
              ]),
              _c("el-input", {
                staticClass: "input",
                attrs: { placeholder: "请输入" },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.qingHaiJMobile,
                  callback: function ($$v) {
                    _vm.qingHaiJMobile =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "qingHaiJMobile",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.qingHaiJPhone
        ? _c(
            "div",
            { staticClass: "user field code-flex", attrs: { id: "code" } },
            [
              _c("span", { staticClass: "label required" }, [
                _vm._v("验证码："),
              ]),
              _c("el-input", {
                staticClass: "input",
                attrs: {
                  "prefix-icon": "el-icon-document-checked",
                  placeholder: "请输入",
                  disabled: !_vm.qingHaiJMobile,
                },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.qingHaiJPhoneCode,
                  callback: function ($$v) {
                    _vm.qingHaiJPhoneCode =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "qingHaiJPhoneCode",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "code-btn ease",
                  class: { actives: _vm.qingHaiJcanClick },
                  on: { click: _vm.qingHaiJgetCode },
                },
                [_vm._v(" " + _vm._s(_vm.qingHaiJcodeContent) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.qinghaiClickQr
        ? _c(
            "div",
            { staticClass: "clickQr ease", on: { click: _vm.qinghaiClickQrs } },
            [_vm._v(" 获取身份 ")]
          )
        : _vm._e(),
      _vm.qinghaiSelect
        ? _c(
            "div",
            { staticClass: "user field", attrs: { id: "selectInfo" } },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("身份选择："),
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.changeName },
                  model: {
                    value: _vm.qinghaiJidentityValue,
                    callback: function ($$v) {
                      _vm.qinghaiJidentityValue = $$v
                    },
                    expression: "qinghaiJidentityValue",
                  },
                },
                _vm._l(_vm.qinghaiKeyArray, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.jiLinJPhone
        ? _c(
            "div",
            { staticClass: "user field", attrs: { id: "selectInfo" } },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("身份选择："),
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.changeName },
                  model: {
                    value: _vm.jiLinJidentityValue,
                    callback: function ($$v) {
                      _vm.jiLinJidentityValue = $$v
                    },
                    expression: "jiLinJidentityValue",
                  },
                },
                _vm._l(_vm.jiLinValArray, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.jiLinJPhone
        ? _c(
            "div",
            { staticClass: "user field" },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("手机号码："),
              ]),
              _c("el-input", {
                staticClass: "input",
                attrs: { placeholder: "请输入" },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.jiLinJMobile,
                  callback: function ($$v) {
                    _vm.jiLinJMobile =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "jiLinJMobile",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.jiLinJPhone
        ? _c(
            "div",
            { staticClass: "user field code-flex", attrs: { id: "code" } },
            [
              _c("span", { staticClass: "label required" }, [
                _vm._v("验证码："),
              ]),
              _c("el-input", {
                staticClass: "input",
                attrs: {
                  "prefix-icon": "el-icon-document-checked",
                  placeholder: "请输入",
                  disabled: !_vm.jiLinJMobile,
                },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.jiLinJPhoneCode,
                  callback: function ($$v) {
                    _vm.jiLinJPhoneCode =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "jiLinJPhoneCode",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "code-btn ease",
                  class: { actives: _vm.jiLinJcanClick },
                  on: { click: _vm.jiLinJgetCode },
                },
                [_vm._v(" " + _vm._s(_vm.jiLinJcodeContent) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.yunNanJSelect
        ? _c(
            "div",
            { staticClass: "user field", attrs: { id: "selectInfo" } },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("身份选择："),
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.changeName },
                  model: {
                    value: _vm.yunNanJidentityValue,
                    callback: function ($$v) {
                      _vm.yunNanJidentityValue = $$v
                    },
                    expression: "yunNanJidentityValue",
                  },
                },
                _vm._l(_vm.yunnanValArray, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: {
                      label:
                        item.xm +
                        "/" +
                        item.sfzjhm +
                        "/" +
                        item.sjhm +
                        "/" +
                        item.type,
                      value: index,
                    },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.yunnanValArray[_vm.yunNanJidentityValue]
        ? _c("div", [
            _vm.yunNanJSelect
              ? _c(
                  "div",
                  { staticClass: "user field", attrs: { id: "selectInfo" } },
                  [
                    _c("div", { staticClass: "label required" }, [
                      _vm._v("选择方式："),
                    ]),
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.yunnanRadio,
                          callback: function ($$v) {
                            _vm.yunnanRadio = $$v
                          },
                          expression: "yunnanRadio",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "PWD" } }, [
                          _vm._v("密码方式"),
                        ]),
                        _c("el-radio", { attrs: { label: "DX" } }, [
                          _vm._v("短信方式"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.yunNanJSelect && _vm.yunnanRadio == "PWD"
              ? _c(
                  "div",
                  { staticClass: "user field", attrs: { id: "selectInfo" } },
                  [
                    _c("div", { staticClass: "label required" }, [
                      _vm._v("密码："),
                    ]),
                    _c("el-input", {
                      staticClass: "input",
                      attrs: {
                        placeholder: "请输入密码",
                        type: "password",
                        "show-password": true,
                        "auto-complete": "new-password",
                      },
                      on: { input: _vm.changeName },
                      model: {
                        value: _vm.yunNanJPwd,
                        callback: function ($$v) {
                          _vm.yunNanJPwd =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "yunNanJPwd",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.yunNanJSelect && _vm.yunnanRadio == "DX"
              ? _c(
                  "div",
                  {
                    staticClass: "user field code-flex",
                    attrs: { id: "code" },
                  },
                  [
                    _c("span", { staticClass: "label required" }, [
                      _vm._v("验证码："),
                    ]),
                    _c("el-input", {
                      staticClass: "input",
                      attrs: {
                        "prefix-icon": "el-icon-document-checked",
                        placeholder: "请输入",
                      },
                      on: { input: _vm.changeName },
                      model: {
                        value: _vm.yunNanJPhoneCode,
                        callback: function ($$v) {
                          _vm.yunNanJPhoneCode =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "yunNanJPhoneCode",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "code-btn ease",
                        class: { actives: _vm.yunNanJcanClick },
                        on: { click: _vm.yunNanJgetCode },
                      },
                      [_vm._v(" " + _vm._s(_vm.yunNanJcodeContent) + " ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.heiLongJPhone
        ? _c(
            "div",
            { staticClass: "user field" },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("手机号码："),
              ]),
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.heiLongJMobile,
                  callback: function ($$v) {
                    _vm.heiLongJMobile =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "heiLongJMobile",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.heiLongJPhone
        ? _c(
            "div",
            { staticClass: "user field code-flex", attrs: { id: "code" } },
            [
              _c("span", { staticClass: "label required" }, [
                _vm._v("验证码："),
              ]),
              _c("el-input", {
                staticClass: "input",
                attrs: {
                  "prefix-icon": "el-icon-document-checked",
                  placeholder: "请输入",
                },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.heiLongJPhoneCode,
                  callback: function ($$v) {
                    _vm.heiLongJPhoneCode =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "heiLongJPhoneCode",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "code-btn ease",
                  class: { actives: _vm.heiLongJcanClick },
                  on: { click: _vm.heiLongJgetCode },
                },
                [_vm._v(" " + _vm._s(_vm.heiLongJcodeContent) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.heiLongJGet
        ? _c(
            "div",
            { staticClass: "clickQr ease", on: { click: _vm.heiLongJGetInfo } },
            [_vm._v("点击验证")]
          )
        : _vm._e(),
      _vm.heiLongJSelect
        ? _c(
            "div",
            { staticClass: "user field", attrs: { id: "selectInfo" } },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("身份选择："),
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.changeName },
                  model: {
                    value: _vm.heiLongJidentityValue,
                    callback: function ($$v) {
                      _vm.heiLongJidentityValue = $$v
                    },
                    expression: "heiLongJidentityValue",
                  },
                },
                _vm._l(_vm.keyArray, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.heiLongJSelect
        ? _c(
            "div",
            { staticClass: "user field", attrs: { id: "selectInfo" } },
            [
              _c("div", { staticClass: "label required" }, [_vm._v("密码：")]),
              _c("el-input", {
                staticClass: "input",
                attrs: {
                  placeholder: "请输入密码",
                  type: "password",
                  "show-password": true,
                  "auto-complete": "new-password",
                },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.heiLongJPwd,
                  callback: function ($$v) {
                    _vm.heiLongJPwd = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "heiLongJPwd",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.tianJinPhone
        ? _c(
            "div",
            { staticClass: "user field" },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("身份证号/税号/手机号码："),
              ]),
              _c("el-input", {
                staticClass: "input",
                attrs: { placeholder: "请输入" },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.tianJinMobile,
                  callback: function ($$v) {
                    _vm.tianJinMobile =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "tianJinMobile",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.sichuanPhone
        ? _c(
            "div",
            { staticClass: "user field" },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("手机号码："),
              ]),
              _c("el-input", {
                staticClass: "input",
                attrs: { placeholder: "请输入" },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.sichuanMobile,
                  callback: function ($$v) {
                    _vm.sichuanMobile =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "sichuanMobile",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.sichuanPhone
        ? _c(
            "div",
            { staticClass: "user field code-flex", attrs: { id: "code" } },
            [
              _c("span", { staticClass: "label required" }, [
                _vm._v("验证码："),
              ]),
              _c("el-input", {
                staticClass: "input",
                attrs: {
                  "prefix-icon": "el-icon-document-checked",
                  placeholder: "请输入",
                },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.sichuanPhoneCode,
                  callback: function ($$v) {
                    _vm.sichuanPhoneCode =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "sichuanPhoneCode",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "code-btn ease",
                  class: { actives: _vm.sichuancanClick },
                  on: { click: _vm.sichuangetCode },
                },
                [_vm._v(" " + _vm._s(_vm.sichuancodeContent) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.fujianInfoListSelect
        ? _c(
            "div",
            { staticClass: "user field", attrs: { id: "selectInfo" } },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("身份选择："),
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.fujianChangeName },
                  model: {
                    value: _vm.fujianInfoListSelectValue,
                    callback: function ($$v) {
                      _vm.fujianInfoListSelectValue = $$v
                    },
                    expression: "fujianInfoListSelectValue",
                  },
                },
                _vm._l(_vm.fujianvalueListKey, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.fujianPhone
        ? _c(
            "div",
            { staticClass: "user field code-flex", attrs: { id: "code" } },
            [
              _c("span", { staticClass: "label required" }, [
                _vm._v("验证码："),
              ]),
              _c("el-input", {
                staticClass: "input",
                attrs: {
                  "prefix-icon": "el-icon-document-checked",
                  placeholder: "请输入",
                },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.fujianPhoneCode,
                  callback: function ($$v) {
                    _vm.fujianPhoneCode =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "fujianPhoneCode",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "code-btn ease",
                  class: { actives: _vm.fujiancanClick },
                  on: { click: _vm.fujiangetCode },
                },
                [_vm._v(" " + _vm._s(_vm.fujiancodeContent) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showShanXiPhone
        ? _c(
            "div",
            { staticClass: "user field" },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("手机号码："),
              ]),
              _c("el-input", {
                staticClass: "input",
                attrs: { placeholder: "请输入" },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.showShanXiPhoneData,
                  callback: function ($$v) {
                    _vm.showShanXiPhoneData =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "showShanXiPhoneData",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showShanXiPhone
        ? _c(
            "div",
            { staticClass: "user field code-flex", attrs: { id: "code" } },
            [
              _c("span", { staticClass: "label required" }, [
                _vm._v("验证码："),
              ]),
              _c("el-input", {
                staticClass: "input",
                attrs: {
                  "prefix-icon": "el-icon-document-checked",
                  placeholder: "请输入",
                },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.showShanXiPhoneCode,
                  callback: function ($$v) {
                    _vm.showShanXiPhoneCode =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "showShanXiPhoneCode",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "code-btn ease",
                  class: { actives: _vm.sShanxicanClick },
                  on: { click: _vm.sShanxigetCode },
                },
                [_vm._v(" " + _vm._s(_vm.sShanxicodeContent) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.sShanxiGetInfoQr
        ? _c(
            "div",
            { staticClass: "clickQr ease", on: { click: _vm.sShanxiGetInfo } },
            [_vm._v("获取")]
          )
        : _vm._e(),
      _vm.jiangxiInfoListSelect
        ? _c(
            "div",
            { staticClass: "user field", attrs: { id: "selectInfo" } },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("身份选择："),
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.jiangxiChangeName },
                  model: {
                    value: _vm.jiangxiInfoListSelectValue,
                    callback: function ($$v) {
                      _vm.jiangxiInfoListSelectValue = $$v
                    },
                    expression: "jiangxiInfoListSelectValue",
                  },
                },
                _vm._l(_vm.jiangxivalueListKey, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.jiangxiInfoListSelectCode
        ? _c(
            "div",
            { staticClass: "user field code-flex", attrs: { id: "code" } },
            [
              _c("span", { staticClass: "label required" }, [
                _vm._v("验证码："),
              ]),
              _c("el-input", {
                staticClass: "input",
                attrs: {
                  "prefix-icon": "el-icon-document-checked",
                  placeholder: "请输入",
                },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.jiangxidqmobileCode,
                  callback: function ($$v) {
                    _vm.jiangxidqmobileCode =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "jiangxidqmobileCode",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "code-btn ease",
                  class: { actives: _vm.jiangxidqcanClick },
                  on: { click: _vm.jiangxidqgetCode },
                },
                [_vm._v(" " + _vm._s(_vm.jiangxidqcodeContent) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.hebeiInfoListSelect
        ? _c(
            "div",
            { staticClass: "user field", attrs: { id: "selectInfo" } },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("身份选择："),
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.hebeidqChangeName },
                  model: {
                    value: _vm.hebeiInfoListSelectValue,
                    callback: function ($$v) {
                      _vm.hebeiInfoListSelectValue = $$v
                    },
                    expression: "hebeiInfoListSelectValue",
                  },
                },
                _vm._l(_vm.hebeiIdentyList.bsrs, function (item, index) {
                  return _c("el-option", {
                    key: item.bdgxId,
                    attrs: {
                      label: item.xm + "  " + item.mobile + "  " + item.sfzjhm,
                      value: index,
                    },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.hebeiName
        ? _c(
            "div",
            { staticClass: "user field" },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("用户名："),
              ]),
              _c("el-input", {
                attrs: { placeholder: "请输入电子税务局账号或税号" },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.hebeiNameValue,
                  callback: function ($$v) {
                    _vm.hebeiNameValue =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "hebeiNameValue",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.hebeiInfoListSelectCode
        ? _c(
            "div",
            { staticClass: "user field code-flex", attrs: { id: "code" } },
            [
              _c("span", { staticClass: "label required" }, [
                _vm._v("验证码："),
              ]),
              _c("el-input", {
                staticClass: "input",
                attrs: {
                  "prefix-icon": "el-icon-document-checked",
                  placeholder: "请输入",
                },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.hebeidqmobileCode,
                  callback: function ($$v) {
                    _vm.hebeidqmobileCode =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "hebeidqmobileCode",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "code-btn ease",
                  class: { actives: _vm.hebeidqcanClick },
                  on: { click: _vm.hebeidqgetCode },
                },
                [_vm._v(" " + _vm._s(_vm.hebeidqcodeContent) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.shanxiInfoListSelect
        ? _c(
            "div",
            { staticClass: "user field", attrs: { id: "selectInfo" } },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("身份选择："),
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.shanxidqChangeName },
                  model: {
                    value: _vm.shanxiInfoListSelectValue,
                    callback: function ($$v) {
                      _vm.shanxiInfoListSelectValue = $$v
                    },
                    expression: "shanxiInfoListSelectValue",
                  },
                },
                _vm._l(_vm.shanxiInfoListKey, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.name, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.shanxiInfoListSelectMsg
        ? _c(
            "div",
            { staticClass: "user field", attrs: { id: "selectInfo" } },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("办税人选择："),
              ]),
              _vm.shanxiMsg.cwfzr
                ? [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择cwfzr" },
                        on: { change: _vm.shanxidqChangeNameMsg },
                        model: {
                          value: _vm.shanxiInfoListSelectValueMsg,
                          callback: function ($$v) {
                            _vm.shanxiInfoListSelectValueMsg = $$v
                          },
                          expression: "shanxiInfoListSelectValueMsg",
                        },
                      },
                      _vm._l(_vm.shanxiMsg.cwfzr, function (item, index) {
                        return _c("el-option", {
                          key: item.reqid,
                          attrs: {
                            label: item.xm + "  " + item.dh,
                            value: index,
                          },
                        })
                      }),
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.shanxiMsg.fddbr
                ? [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择fddbr" },
                        on: { change: _vm.shanxidqChangeNameMsg },
                        model: {
                          value: _vm.shanxiInfoListSelectValueMsg,
                          callback: function ($$v) {
                            _vm.shanxiInfoListSelectValueMsg = $$v
                          },
                          expression: "shanxiInfoListSelectValueMsg",
                        },
                      },
                      _vm._l(_vm.shanxiMsg.fddbr, function (item, index) {
                        return _c("el-option", {
                          key: item.reqid,
                          attrs: {
                            label: item.xm + "  " + item.dh,
                            value: index,
                          },
                        })
                      }),
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.shanxiMsg.bsr
                ? [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择bsr" },
                        on: { change: _vm.shanxidqChangeNameMsg },
                        model: {
                          value: _vm.shanxiInfoListSelectValueMsg,
                          callback: function ($$v) {
                            _vm.shanxiInfoListSelectValueMsg = $$v
                          },
                          expression: "shanxiInfoListSelectValueMsg",
                        },
                      },
                      _vm._l(_vm.shanxiMsg.bsr, function (item, index) {
                        return _c("el-option", {
                          key: item.reqid,
                          attrs: {
                            label: item.xm + "  " + item.dh,
                            value: index,
                          },
                        })
                      }),
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm.shanxiMsgBsrSelect
        ? _c(
            "div",
            { staticClass: "user field", attrs: { id: "selectInfo" } },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("办税人选择："),
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择bsr" },
                  on: { change: _vm.newShanxidqChangeNameMsg },
                  model: {
                    value: _vm.newShanxiInfoListSelectValueMsg,
                    callback: function ($$v) {
                      _vm.newShanxiInfoListSelectValueMsg = $$v
                    },
                    expression: "newShanxiInfoListSelectValueMsg",
                  },
                },
                _vm._l(_vm.shanxiMsgBsr, function (item, index) {
                  return _c("el-option", {
                    key: item.reqid,
                    attrs: { label: item.xm + "  " + item.dh, value: index },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.shanxiInfoListSelectCode
        ? _c(
            "div",
            { staticClass: "user field code-flex", attrs: { id: "code" } },
            [
              _c("span", { staticClass: "label required" }, [
                _vm._v("验证码："),
              ]),
              _c("el-input", {
                staticClass: "input",
                attrs: {
                  "prefix-icon": "el-icon-document-checked",
                  placeholder: "请输入",
                },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.shanxidqmobileCode,
                  callback: function ($$v) {
                    _vm.shanxidqmobileCode =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "shanxidqmobileCode",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "code-btn ease",
                  class: { actives: _vm.shanxidqcanClick },
                  on: { click: _vm.shanxidqgetCode },
                },
                [_vm._v(" " + _vm._s(_vm.shanxidqcodeContent) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.hunanInfoListSelect
        ? _c(
            "div",
            { staticClass: "user field code-flex", attrs: { id: "code" } },
            [
              _c("span", { staticClass: "label required" }, [
                _vm._v("验证码："),
              ]),
              _c("el-input", {
                staticClass: "input",
                attrs: {
                  "prefix-icon": "el-icon-document-checked",
                  placeholder: "请输入",
                },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.hunandqmobileCode,
                  callback: function ($$v) {
                    _vm.hunandqmobileCode =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "hunandqmobileCode",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "code-btn ease",
                  class: { actives: _vm.hunandqcanClick },
                  on: { click: _vm.hunandqgetCode },
                },
                [_vm._v(" " + _vm._s(_vm.hunandqcodeContent) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.henanInfoListSelect
        ? _c(
            "div",
            { staticClass: "user field", attrs: { id: "selectInfo" } },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("身份证/用户名/手机号："),
              ]),
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.hnMobile,
                  callback: function ($$v) {
                    _vm.hnMobile = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "hnMobile",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.henanInfoListSelect
        ? _c(
            "div",
            { staticClass: "user field code-flex", attrs: { id: "code" } },
            [
              _c("span", { staticClass: "label required" }, [
                _vm._v("验证码："),
              ]),
              _c("el-input", {
                staticClass: "input",
                attrs: {
                  "prefix-icon": "el-icon-document-checked",
                  placeholder: "请输入",
                },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.hndqmobileCode,
                  callback: function ($$v) {
                    _vm.hndqmobileCode =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "hndqmobileCode",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "code-btn ease",
                  class: { actives: _vm.hndqcanClick },
                  on: { click: _vm.hndqgetCode },
                },
                [_vm._v(" " + _vm._s(_vm.hndqcodeContent) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.gcVerifiType
        ? _c(
            "div",
            { staticClass: "user field", attrs: { id: "selectInfo" } },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("身份选择："),
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.changeName },
                  model: {
                    value: _vm.gcVerifiTypeValue,
                    callback: function ($$v) {
                      _vm.gcVerifiTypeValue = $$v
                    },
                    expression: "gcVerifiTypeValue",
                  },
                },
                _vm._l(_vm.gcnameList, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.gcVerifiType
        ? _c(
            "div",
            { staticClass: "user field code-flex", attrs: { id: "code" } },
            [
              _c("span", { staticClass: "label required" }, [
                _vm._v("验证码："),
              ]),
              _c("el-input", {
                attrs: {
                  "prefix-icon": "el-icon-document-checked",
                  placeholder: "请输入",
                },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.gcmobileCode,
                  callback: function ($$v) {
                    _vm.gcmobileCode =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "gcmobileCode",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "code-btn ease",
                  class: { actives: _vm.gccanClick },
                  on: { click: _vm.gcgetCode },
                },
                [_vm._v(" " + _vm._s(_vm.gccodeContent) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.lnVerifiType
        ? _c(
            "div",
            { staticClass: "user field", attrs: { id: "selectInfo" } },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("身份选择："),
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.changeName },
                  model: {
                    value: _vm.lnVerifiTypeValue,
                    callback: function ($$v) {
                      _vm.lnVerifiTypeValue = $$v
                    },
                    expression: "lnVerifiTypeValue",
                  },
                },
                _vm._l(_vm.lnnameList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.lnVerifiType
        ? _c(
            "div",
            { staticClass: "user field" },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("手机号码："),
              ]),
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.lnmobile,
                  callback: function ($$v) {
                    _vm.lnmobile = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "lnmobile",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.lnVerifiType
        ? _c(
            "div",
            { staticClass: "user field code-flex", attrs: { id: "code" } },
            [
              _c("span", { staticClass: "label required" }, [
                _vm._v("验证码："),
              ]),
              _c("el-input", {
                attrs: {
                  "prefix-icon": "el-icon-document-checked",
                  placeholder: "请输入",
                },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.lnmobileCode,
                  callback: function ($$v) {
                    _vm.lnmobileCode =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "lnmobileCode",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "code-btn ease",
                  class: { actives: _vm.lncanClick },
                  on: { click: _vm.lngetCode },
                },
                [_vm._v(" " + _vm._s(_vm.lncodeContent) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.bjgetTaxPerson
        ? _c(
            "div",
            { staticClass: "user field", attrs: { id: "selectInfo" } },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("办税人员选择："),
              ]),
              _vm.thirdDataSource == "DB_WF"
                ? _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.bjchangeQcode },
                      model: {
                        value: _vm.bjvalue,
                        callback: function ($$v) {
                          _vm.bjvalue = $$v
                        },
                        expression: "bjvalue",
                      },
                    },
                    _vm._l(_vm.bjnameListArr, function (item) {
                      return _c("el-option", {
                        key: item.key,
                        attrs: {
                          label:
                            item.xm +
                            "  " +
                            item.zjhm +
                            "  " +
                            item.sjhm +
                            "  " +
                            item.sfmc,
                          value: item.key,
                        },
                      })
                    }),
                    1
                  )
                : _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.bjchangeQcode },
                      model: {
                        value: _vm.bjvalue,
                        callback: function ($$v) {
                          _vm.bjvalue = $$v
                        },
                        expression: "bjvalue",
                      },
                    },
                    _vm._l(_vm.newBjList, function (item) {
                      return _c("el-option", {
                        key: item.key,
                        attrs: { label: item.key, value: item.val },
                      })
                    }),
                    1
                  ),
            ],
            1
          )
        : _vm._e(),
      _vm.bjverificationMethod && _vm.thirdDataSource != "DB_WF"
        ? _c(
            "div",
            { staticClass: "user field" },
            [
              _c("span", { staticClass: "label required" }, [
                _vm._v("办税人姓名： "),
              ]),
              _c("el-input", {
                attrs: { placeholder: "请输入办税人姓名" },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.bjNewName,
                  callback: function ($$v) {
                    _vm.bjNewName = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "bjNewName",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.bjverificationMethod && _vm.thirdDataSource != "DB_WF"
        ? _c(
            "div",
            { staticClass: "user field" },
            [
              _c("span", { staticClass: "label required" }, [
                _vm._v("身份类型： "),
              ]),
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.changeName },
                  model: {
                    value: _vm.bjNewIndenty,
                    callback: function ($$v) {
                      _vm.bjNewIndenty = $$v
                    },
                    expression: "bjNewIndenty",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v("财务负责人"),
                  ]),
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _vm._v("法定代表人"),
                  ]),
                  _c("el-radio", { attrs: { label: 3 } }, [_vm._v("办税人")]),
                  _c("el-radio", { attrs: { label: 4 } }, [_vm._v("购票员")]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.bjverificationMethod
        ? _c(
            "div",
            { staticClass: "user field" },
            [
              _c("span", { staticClass: "label required" }, [
                _vm._v("手机号： "),
              ]),
              _c("el-input", {
                attrs: { placeholder: "请输入手机号" },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.bjNewPassword,
                  callback: function ($$v) {
                    _vm.bjNewPassword =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "bjNewPassword",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.bjverificationMethod
        ? _c(
            "div",
            { staticClass: "user field" },
            [
              _c("span", { staticClass: "label required" }, [
                _vm._v("身份证号： "),
              ]),
              _c("el-input", {
                attrs: { placeholder: "请输入身份证号" },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.bjnewIdentCode,
                  callback: function ($$v) {
                    _vm.bjnewIdentCode =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "bjnewIdentCode",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.bjverificationMethod
        ? _c(
            "div",
            { staticClass: "user field" },
            [
              _c("span", { staticClass: "label required" }, [
                _vm._v("验证方式(密码)： "),
              ]),
              _c("el-input", {
                attrs: {
                  placeholder: "请输入密码",
                  type: "password",
                  "show-password": true,
                  "auto-complete": "new-password",
                },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.bjpasswordInput,
                  callback: function ($$v) {
                    _vm.bjpasswordInput =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "bjpasswordInput",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.hngetTaxPerson
        ? _c(
            "div",
            { staticClass: "user field", attrs: { id: "selectInfo" } },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("办税人员选择："),
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.hnchangeQcode },
                  model: {
                    value: _vm.hnvalue,
                    callback: function ($$v) {
                      _vm.hnvalue = $$v
                    },
                    expression: "hnvalue",
                  },
                },
                _vm._l(_vm.hnArr, function (item) {
                  return _c("el-option", {
                    key: item.uuid,
                    attrs: {
                      label: item.xm + "  " + item.sfzjhm + "  " + item.sjhm,
                      value: item.uuid,
                    },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.xjgetTaxPerson
        ? _c(
            "div",
            { staticClass: "user field", attrs: { id: "selectInfo" } },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("办税人员选择："),
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.xjchangeQcode },
                  model: {
                    value: _vm.xjvalue,
                    callback: function ($$v) {
                      _vm.xjvalue = $$v
                    },
                    expression: "xjvalue",
                  },
                },
                _vm._l(_vm.xjnameListArr, function (item) {
                  return _c("el-option", {
                    key: item.bdgxId,
                    attrs: {
                      label:
                        item.xm +
                        "  " +
                        item.sfzjhm +
                        "  " +
                        item.mobile +
                        " " +
                        _vm.xjPersonType[item.bsrType],
                      value: item.bdgxId,
                    },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.xjshowInfo
        ? _c("div", { staticClass: "user field" }, [
            _c("div", { staticClass: "label required" }, [_vm._v("用户名：")]),
            _vm._v(" " + _vm._s(_vm.xjshowInfo) + " "),
          ])
        : _vm._e(),
      _vm.xjshowInfo
        ? _c(
            "div",
            { staticClass: "user field code-flex", attrs: { id: "code" } },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("验证码："),
              ]),
              _c("el-input", {
                attrs: {
                  "prefix-icon": "el-icon-document-checked",
                  placeholder: "请输入",
                },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.xjmobileCode,
                  callback: function ($$v) {
                    _vm.xjmobileCode =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "xjmobileCode",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "code-btn ease",
                  class: { actives: _vm.xjcanClick },
                  on: { click: _vm.xjgetCode },
                },
                [_vm._v(" " + _vm._s(_vm.xjcodeContent) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.shGetTaxPerson
        ? _c(
            "div",
            { staticClass: "user field" },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("身份证/用户名/手机号："),
              ]),
              _c("el-input", {
                staticClass: "input",
                attrs: { placeholder: "身份证/用户名/手机号" },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.shNameListValue,
                  callback: function ($$v) {
                    _vm.shNameListValue =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "shNameListValue",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.shGetTaxPerson
        ? _c(
            "div",
            { staticClass: "user field code-flex", attrs: { id: "code" } },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("验证码："),
              ]),
              _c("el-input", {
                attrs: {
                  "prefix-icon": "el-icon-document-checked",
                  placeholder: "请输入",
                },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.shmobileCode,
                  callback: function ($$v) {
                    _vm.shmobileCode =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "shmobileCode",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "code-btn ease",
                  class: { actives: _vm.shcanClick },
                  on: { click: _vm.shgetCode },
                },
                [_vm._v(" " + _vm._s(_vm.shcodeContent) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.getTaxPerson
        ? _c(
            "div",
            { staticClass: "user field", attrs: { id: "selectInfo" } },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("办税人员选择："),
              ]),
              _vm.thirdDataSource == "DB_WF"
                ? _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.changeQcode },
                      model: {
                        value: _vm.value,
                        callback: function ($$v) {
                          _vm.value = $$v
                        },
                        expression: "value",
                      },
                    },
                    _vm._l(_vm.nameListArr, function (item) {
                      return _c("el-option", {
                        key: item.uuid,
                        attrs: {
                          label:
                            item.xm + "  " + item.sfzjhm + "  " + item.sjhm,
                          value: item.uuid,
                        },
                      })
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.kuanGuZjMethod
        ? _c(
            "div",
            { staticClass: "user field" },
            [
              _c("span", { staticClass: "label required" }, [
                _vm._v("委托人手机号/身份证号码/操作员代码："),
              ]),
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.zjBsryxm,
                  callback: function ($$v) {
                    _vm.zjBsryxm = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "zjBsryxm",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.zJselection
        ? _c(
            "div",
            { staticClass: "user field", attrs: { id: "selectInfo" } },
            [
              _c("span", { staticClass: "label required" }, [
                _vm._v("登录身份："),
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.changeName },
                  model: {
                    value: _vm.zJSelectRoleValue,
                    callback: function ($$v) {
                      _vm.zJSelectRoleValue = $$v
                    },
                    expression: "zJSelectRoleValue",
                  },
                },
                _vm._l(_vm.zJSelectRoleList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.name, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.verificationMethod
        ? _c(
            "div",
            { staticClass: "user field" },
            [
              _c("span", { staticClass: "label required" }, [
                _vm._v("输入密码："),
              ]),
              _c("el-input", {
                attrs: {
                  placeholder: "请输入密码",
                  type: "password",
                  "show-password": true,
                  "auto-complete": "new-password",
                },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.passwordInput,
                  callback: function ($$v) {
                    _vm.passwordInput =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "passwordInput",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.identity
        ? _c(
            "div",
            { staticClass: "user field", attrs: { id: "selectInfo" } },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("身份选择："),
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.changeIdentity },
                  model: {
                    value: _vm.identityValue,
                    callback: function ($$v) {
                      _vm.identityValue = $$v
                    },
                    expression: "identityValue",
                  },
                },
                _vm._l(_vm.identityList, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.selection
        ? _c(
            "div",
            { staticClass: "user field" },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("人员选择："),
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.changeIdentityRole },
                  model: {
                    value: _vm.jsSelectRoleValue,
                    callback: function ($$v) {
                      _vm.jsSelectRoleValue = $$v
                    },
                    expression: "jsSelectRoleValue",
                  },
                },
                _vm._l(_vm.jsSelectRoleList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item, value: index },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showCode
        ? _c(
            "div",
            { staticClass: "user field code-flex", attrs: { id: "code" } },
            [
              _c("span", { staticClass: "label required" }, [
                _vm._v("验证码："),
              ]),
              _c("el-input", {
                attrs: {
                  "prefix-icon": "el-icon-document-checked",
                  placeholder: "请输入",
                },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.jsmobileCode,
                  callback: function ($$v) {
                    _vm.jsmobileCode =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "jsmobileCode",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "code-btn ease",
                  class: { actives: _vm.jscanClick },
                  on: { click: _vm.jsgetCode },
                },
                [_vm._v(" " + _vm._s(_vm.jscodeContent) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.SDMethod
        ? _c(
            "div",
            { staticClass: "user field" },
            [
              _c("span", { staticClass: "label required" }, [
                _vm._v("委托人手机号/身份证号码/操作员代码："),
              ]),
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.czydm,
                  callback: function ($$v) {
                    _vm.czydm = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "czydm",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.nbVerifiType
        ? _c(
            "div",
            { staticClass: "user field" },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("手机号码/用户名/身份证号："),
              ]),
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.mobile,
                  callback: function ($$v) {
                    _vm.mobile = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "mobile",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.nbVerifiType
        ? _c(
            "div",
            { staticClass: "user field code-flex", attrs: { id: "code" } },
            [
              _c("span", { staticClass: "label required" }, [
                _vm._v("验证码："),
              ]),
              _c("el-input", {
                attrs: {
                  "prefix-icon": "el-icon-document-checked",
                  placeholder: "请输入",
                },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.mobileCode,
                  callback: function ($$v) {
                    _vm.mobileCode = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "mobileCode",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "code-btn ease",
                  class: { actives: _vm.canClick },
                  on: { click: _vm.getCode },
                },
                [_vm._v(" " + _vm._s(_vm.codeContent) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.cqVerifiType
        ? _c(
            "div",
            { staticClass: "user field" },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("用户名："),
              ]),
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.cqmobile,
                  callback: function ($$v) {
                    _vm.cqmobile = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "cqmobile",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.cqVerifiTypeNext
        ? _c(
            "div",
            { staticClass: "clickQr ease", on: { click: _vm.clickQrsCq } },
            [_vm._v("下一步")]
          )
        : _vm._e(),
      _vm.cqVerifiTypeCode
        ? _c(
            "div",
            { staticClass: "user field" },
            [
              _c("div", { staticClass: "label required" }, [
                _vm._v("手机号："),
              ]),
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.cqmobileOne,
                  callback: function ($$v) {
                    _vm.cqmobileOne = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "cqmobileOne",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.cqVerifiTypeCode
        ? _c(
            "div",
            { staticClass: "user field code-flex", attrs: { id: "code" } },
            [
              _c("span", { staticClass: "label required" }, [
                _vm._v("验证码："),
              ]),
              _c("el-input", {
                attrs: {
                  "prefix-icon": "el-icon-document-checked",
                  placeholder: "请输入",
                },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.cqmobileCode,
                  callback: function ($$v) {
                    _vm.cqmobileCode =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "cqmobileCode",
                },
              }),
              this.cqVerifiTypeNextCode
                ? _c(
                    "div",
                    {
                      staticClass: "code-btn ease",
                      class: { actives: _vm.cqcanClick },
                      on: { click: _vm.cqgetCode },
                    },
                    [_vm._v(" " + _vm._s(_vm.cqcodeContent) + " ")]
                  )
                : _c(
                    "div",
                    {
                      staticClass: "code-btn ease",
                      class: { actives: _vm.cqcanClick },
                      on: { click: _vm.cqgetCode },
                    },
                    [_vm._v(" 再次获取验证码 ")]
                  ),
            ],
            1
          )
        : _vm._e(),
      _vm.cqVerifiTypeNextCode
        ? _c(
            "div",
            { staticClass: "clickQr ease", on: { click: _vm.clickQrsCqCode } },
            [_vm._v(" 下一步 ")]
          )
        : _vm._e(),
      _vm.nmVerifiType
        ? _c(
            "div",
            { staticClass: "user field mobile-input mobile-input-nm" },
            [
              _c("span", { staticClass: "label required" }, [
                _vm._v("身份证/用户名/手机号："),
              ]),
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.nmMobile,
                  callback: function ($$v) {
                    _vm.nmMobile = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "nmMobile",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.nmVerifiType
        ? _c(
            "div",
            { staticClass: "user field code-flex", attrs: { id: "code" } },
            [
              _c("span", { staticClass: "label required" }, [
                _vm._v("验证码："),
              ]),
              _c("el-input", {
                attrs: {
                  "prefix-icon": "el-icon-document-checked",
                  placeholder: "请输入",
                },
                on: { input: _vm.changeName },
                model: {
                  value: _vm.nmMobileCode,
                  callback: function ($$v) {
                    _vm.nmMobileCode =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "nmMobileCode",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "code-btn ease",
                  class: { actives: _vm.nmcanClick },
                  on: { click: _vm.nmGetCode },
                },
                [_vm._v(" " + _vm._s(_vm.nmCodeContent) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _vm._v(" 申报数据采集 "),
      _c("span", { staticClass: "tiptitle" }, [
        _vm._v(
          "温馨提示：同一时间同一账号不允许多站点登陆，若强行登陆会导致系统数据采集中断无法生成检测报告！"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }