var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isloaded
      ? _c("div", { staticClass: "dali-style" }, [
          _c("div", { staticClass: "page-wrapper" }, [
            _c(
              "div",
              { staticClass: "section-box" },
              [
                !_vm.isTaxAutoProcessing
                  ? _c("report-method", {
                      ref: "showReport",
                      staticClass: "report-method",
                      attrs: {
                        "api-prefix": "daqc",
                        thirdDataSource: _vm.thirdDataSource,
                        zq: _vm.zq,
                        uuid: _vm.uuid,
                        "company-info": _vm.companyInfo,
                      },
                      on: { setName: _vm.changeName, skip: _vm.handleSkip },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "footer2" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-next",
                on: { click: _vm.handleBtnNext },
              },
              [_vm._v("提交")]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }